<template>
  <DetailContainer>
    <div class="page_container doctorResMng_detail_page">
      <el-form ref="formRef" :model="formModel.form" label-width="110px" label-position="right">
        <!-- 医生信息1 -->
        <div class="content_title">
          <IconVertical style="margin-right: 10px" />
          医生信息
        </div>
        <div class="form">
          <el-row :gutter="10">
            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="医生姓名" prop="doctorName" :rules="[{ required: true, message: '请输入医生姓名' }]">
                <el-input v-model="formModel.form.doctorName" placeholder="请输入医生姓名" clearable :disabled="!isNeedAppr"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="医疗机构">
                <div>{{ formModel.form.hospitalName }}</div>
              </el-form-item>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="所在科室">
                <div>{{ formModel.form.departName }}</div>
              </el-form-item>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="职称" prop="doctorTitleCode" :rules="[{ required: true, message: '请选择职称' }]">
                <FetchSelect
                  v-model="formModel.form.doctorTitleCode"
                  api="/web/sys/code/getSysCodeByParam"
                  :postData="{ codeParam: 'DOCTOR_TITLE' }"
                  textKey="codeValue"
                  valueKey="codeKey"
                  :disabled="!isNeedAppr"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item
                label="手机号码"
                prop="phoneNumber"
                :rules="[
                  { required: true, message: '请输入手机号码' },
                  { pattern: patternCreator.mobilePhone.pattern, message: '请输入正确的手机号' },
                ]"
              >
                <el-input v-model="formModel.form.phoneNumber" placeholder="请输入手机号码" clearable :disabled="!isNeedAppr"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item
                label="证件号码"
                prop="doctorPsptNo"
                :rules="[
                  { required: true, message: '请输入证件号码' },
                  { pattern: patternCreator.identityNo.pattern, message: '请输入正确的身份证号' },
                ]"
              >
                <el-input v-model="formModel.form.doctorPsptNo" placeholder="请输入" clearable :disabled="!isNeedAppr"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="性别" prop="doctorSex" :rules="[{ required: true, message: '请选择性别' }]">
                <FetchSelect
                  v-model="formModel.form.doctorSex"
                  api="/web/sys/code/getSysCodeByParam"
                  :postData="{ codeParam: 'SEX' }"
                  textKey="codeValue"
                  valueKey="codeKey"
                  :disabled="!isNeedAppr"
                />
              </el-form-item>
            </el-col>

            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6"></el-col>

            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="个人简介" prop="doctorDesc">
                <el-input v-model="formModel.form.doctorDesc" placeholder="请输入" type="textarea" clearable :disabled="!isNeedAppr"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="擅长" prop="doctorExpert">
                <el-input v-model="formModel.form.doctorExpert" placeholder="请输入" type="textarea" clearable :disabled="!isNeedAppr"></el-input>
              </el-form-item>
            </el-col>

            <!-- <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="荣誉奖项" prop="doctorHonors">
                <el-input v-model="formModel.form.doctorHonors" placeholder="请输入" type="textarea" clearable></el-input>
              </el-form-item>
            </el-col> -->

            <!-- 一些图片 -->
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="img_form_wrap">
                <div class="el-form--label-top">
                  <el-row :gutter="10">
                    <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="label_top_item">
                      <el-form-item label="身份证头像页" prop="psptPhotoFront">
                        <ImgUpload v-model="formModel.form.psptPhotoFront" :limit="1" :disabled="true" />
                      </el-form-item>
                    </el-col>
                    <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="label_top_item">
                      <el-form-item label="身份证国徽页" prop="psptPhotoBack">
                        <ImgUpload v-model="formModel.form.psptPhotoBack" :limit="1" :disabled="true" />
                      </el-form-item>
                    </el-col>
                    <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class="label_top_item">
                      <el-form-item label="医生照片" prop="doctorHeadImg" :rules="[{ required: true, message: '请添加医生照片' }]">
                        <ImgUploadForCropper v-model="formModel.form.doctorHeadImg" :limit="1" :disabled="!isNeedAppr" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>

            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="医师执业证" prop="practisingCertImg" :rules="[{ required: true, message: '请添加医师执业证' }, { validator: certImgValidator }]">
                <ImgUpload v-model="formModel.form.practisingCertImg" :limit="4" :disabled="!isNeedAppr" />
              </el-form-item>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="职称证书" prop="doctorTitleImg" :rules="[{ required: true, message: '请添加职称证书' }]">
                <ImgUpload v-model="formModel.form.doctorTitleImg" :limit="4" :disabled="!isNeedAppr" />
              </el-form-item>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item label="医师资格证" prop="qualificationCertImg" :rules="[{ required: true, message: '请添加医师资格证' }, { validator: certImgValidator }]">
                <ImgUpload v-model="formModel.form.qualificationCertImg" :limit="4" :disabled="!isNeedAppr" />
              </el-form-item>
            </el-col>
            <!-- 一些图片 end -->
          </el-row>
        </div>

        <!-- 审核 -->
        <template v-if="isNeedAppr">
          <div class="content_title">
            <IconVertical style="margin-right: 10px" />
            审核
          </div>
          <div class="form">
            <el-row :gutter="10">
              <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                <el-form-item label="审核" prop="ifPass" :rules="[{ required: true }]">
                  <el-radio-group v-model="formModel.form.ifPass" @change="handleApprChange">
                    <el-radio :label="1">审核通过</el-radio>
                    <el-radio :label="0">审核驳回</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="formModel.form.ifPass != 1" :gutter="10">
              <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="驳回原因" prop="approveResult" :rules="[{ required: formModel.form.ifPass != 1, message: '请输入驳回原因' }]">
                  <el-input v-model="formModel.form.approveResult" placeholder="请输入驳回原因" clearable></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </template>

        <!-- 医生信息2 -->
        <template v-if="isNeedAppr && formModel.form.ifPass == 1">
          <div class="content_title">
            <IconVertical style="margin-right: 10px" />
            医生信息
          </div>
          <div class="form">
            <el-row :gutter="10">
              <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                <el-form-item label="执业证书编号" prop="practisingCertCode" :rules="[{ required: true, message: '请输入执业证书编号' }]">
                  <el-input v-model="formModel.form.practisingCertCode" placeholder="请输入" clearable :disabled="!isNeedAppr"></el-input>
                </el-form-item>
              </el-col>

              <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                <el-form-item label="执业类别" prop="practisingTypeCode" :rules="[{ required: true, message: '请选择执业类别' }]">
                  <FetchSelect
                    v-model="formModel.form.practisingTypeCode"
                    api="/web/sys/code/getSysCodeByParam"
                    :postData="{ codeParam: 'DOCTOR_PRACTISING_TYPE' }"
                    textKey="codeValue"
                    valueKey="codeKey"
                    :disabled="!isNeedAppr"
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                <el-form-item label="执业范围" prop="practisingScopeCode" :rules="[{ required: true, message: '请选择执业范围' }]">
                  <FetchSelect
                    v-model="formModel.form.practisingScopeCode"
                    api="/web/sys/code/getSysCodeByParam"
                    :postData="{ codeParam: 'DOCTOR_PRACTISING_SCOPE' }"
                    textKey="codeValue"
                    valueKey="codeKey"
                    :disabled="!isNeedAppr"
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                <el-form-item label="执业机构编号" prop="practisingOrgCode" :rules="[{ required: true, message: '请输入执业机构编号' }]">
                  <el-input v-model="formModel.form.practisingOrgCode" placeholder="请输入" clearable :disabled="!isNeedAppr"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                <el-form-item label="登录账号" prop="staffLoginName" :rules="[{ required: true, message: '请输入医生登录账号' }]">
                  <el-input v-model="formModel.form.staffLoginName" placeholder="请输入医生登录账号" clearable :disabled="!isNeedAppr"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                <el-form-item label="医生集团" prop="orgCode" :rules="[{ required: true, message: '请选择在职医生集团' }]">
                  <FetchSelect v-model="formModel.form.orgCode" api="/web/system/doctor/getCompanyList" valueKey="orgCode" textKey="orgName" :disabled="!isNeedAppr" />
                </el-form-item>
              </el-col>
              <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                <el-form-item label="医疗机构" prop="hospitalCode" :rules="[{ required: true, message: '请选择医疗机构' }]">
                  <FetchSelect
                    v-model="formModel.form.hospitalCode"
                    api="/web/system/doctor/getHospitalList"
                    valueKey="hospitalCode"
                    textKey="hospitalName"
                    :disabled="!isNeedAppr"
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
                <el-form-item label="所在科室" prop="departCode" :rules="[{ required: true, message: '请选择所在科室' }]">
                  <FetchSelect v-model="formModel.form.departCode" api="/web/system/doctor/getDepartList" valueKey="departCode" textKey="departName" :disabled="!isNeedAppr" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </template>

        <!-- 操作 -->
        <div class="form" style="margin-left: 170px">
          <el-form-item>
            <SubmitButton v-if="isNeedAppr" @click="handleSubmit" :loading="submitLoading">提交</SubmitButton>
            <el-button @click="$router.back">返回</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </DetailContainer>
</template>

<script>
import { ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import DetailContainer from '@/components/layout/DetailContainer'
import IconVertical from '@/components/customStyle/IconVertical'
import ImgUpload from '@/components/ImgUpload/index.vue'
import ImgUploadForCropper from '@/components/ImgUploadForCropper/index.vue'
import FetchSelect from '@/components/FetchSelect'
import SubmitButton from '@/components/customStyle/SubmitButton/index.vue'
import useGoBackParams from '@/hooks/router/useGoBackParams'
import useRequestData from '@/hooks/useRequestData'
import { validateFormRef } from '@/common/utils_element'
import request from '@/utils/request'
import { simpleConfirm } from '@/utils/confirm'
import patternCreator from '@/utils/patternCreator'
import { cloneDeep } from '@/utils/utils'

// 审核通过 90 / 审核驳回 93

/**
 * options:
 * staffCode
 *
 * 回退的时候 可以设置：
 * $route.params:
 * isAddSuccess
 * isEditSuccess
 */

// 证书的验证 最少上传2张
function certImgValidator(rule, value, callback) {
  let vArr = value ? value.split(',') : []
  if (vArr.length >= 2) {
    return callback()
  } else {
    return callback(new Error('至少上传2张'))
  }
}

export default {
  data() {
    return { patternCreator, certImgValidator }
  },
  setup() {
    const route = useRoute()
    const goBackParams = useGoBackParams('/web/system/doctor/doctorregistermgr') // 返回一个方法
    const staffCode = route.query.staffCode

    // 审核通过需要填写的信息
    const apprForm = {
      practisingCertCode: '', //    医生执业证书编号(非空)
      practisingTypeCode: '', //    医师执业类别编码(非空)(下拉框,codeParam=DOCTOR_PRACTISING_TYPE)
      practisingScopeCode: '', //   医师执业范围编码(非空)(下拉框,codeParam=DOCTOR_PRACTISING_SCOPE)
      practisingOrgCode: '', //    执业机构编码(非空)
      staffLoginName: '', //              医生登录账号
      orgCode: '', //    医生集团编码(非空)
      hospitalCode: '', //   医疗机构编码(非空)
      departCode: '', //           医疗科室编码(非空)
    }
    const defaultForm = {
      /**
       * 医生信息
       */
      doctorName: '', //          医生姓名(非空)
      hospitalName: '',
      departName: '',
      doctorTitleCode: '', //     职称编码(非空)(下拉框,codeParam=DOCTOR_TITLE)
      phoneNumber: '', //       医生联系电话(非空)
      doctorPsptNo: '', //     医生身份证号(非空)
      doctorSex: '', //     医生性别(非空)(下拉框,codeParam=SEX)
      doctorDesc: '', //        医生介绍
      doctorExpert: '', //       医生擅长
      // doctorHonors: '', //       医生荣誉
      // 一些图片
      psptPhotoFront: '',
      psptPhotoBack: '',
      doctorHeadImg: '', //    医生头像
      doctorTitleImg: '', // 职称证书
      qualificationCertImg: '', // 医生资格证书图片(非空)
      practisingCertImg: '', //    医生执业证书图片(非空)
      // 审核信息
      ifPass: 1, //      是否通过(1-通过，其他-驳回)(非空)
      approveResult: '', //     审核意见(驳回时-非空)
      // 审核通过的 医生信息2
      ...cloneDeep(apprForm),
    }

    const rawData = ref({})
    const isNeedAppr = ref(false) // 是否需要审核
    const formRef = ref(null)
    const formModel = reactive({ form: cloneDeep(defaultForm) })
    const submitLoading = ref(false)

    // 医生信息
    useRequestData({
      api: '/web/system/doctorregister/getDoctorRegisterInfo',
      getPostData: () => ({ staffCode }),
      getIsReady: () => staffCode,
      successAjax: initForm,
    })

    /**
     * 方法
     */
    // 初始化信息
    function initForm(data) {
      console.log('🚀 ~ 医生注册信息 data', data)
      rawData.value = data
      let formData = {}
      for (let key in defaultForm) {
        formData[key] = data[key] || defaultForm[key]
      }
      formModel.form = formData
      isNeedAppr.value = data.status == '10' ? true : false
    }

    function handleApprChange(v) {
      if (v === 0) {
        // 清空 医生信息2
        formModel.form = {
          ...formModel.form,
          ...cloneDeep(apprForm),
        }
      }
    }

    // 提交
    async function handleSubmit() {
      // 验证
      await validateFormRef(formRef)
      // 验证 end

      const postData = {
        staffCode,
        ...formModel.form,
      }
      console.log('🚀 ~ 医生自注册 postData', postData)
      // return

      // 请求
      submitLoading.value = true
      request({
        url: '/web/system/doctorregister/apprDoctorRegister',
        data: postData,
      })
        .finally(() => {
          submitLoading.value = false
        })
        .then(() => {
          ElMessage.success('操作成功')
          // if (!isEdit) goBackParams({ isAddSuccess: true })
          goBackParams({ isEditSuccess: true })
        })
    }

    return {
      rawData,
      isNeedAppr,
      formRef,
      formModel,
      handleApprChange,
      handleSubmit,
      submitLoading,
    }
  },
  components: { DetailContainer, IconVertical, ImgUpload, ImgUploadForCropper, SubmitButton, FetchSelect },
}
</script>

<style lang="less">
@import url('./index_global.less');
</style>

<style lang="less" scoped>
@import url('./index.less');
</style>
